import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  heroContent: {
      padding: theme.spacing(8, 0, 6),
  },

  infoContent: {
      padding: theme.spacing(8, 0, 7),
  }
}));

export default function Courses() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <main>
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h2" align="center" color="Primary" gutterBottom>
            Program Offerings for A.Y. 2023-2024
          </Typography>
          <Typography variant="body1" align="center" color="textPrimary" paragraph>
            The online Admission Application period for all Incoming First Years, and Transferees
            for A.Y. 2023-2024 will be on <strong>February 6, 2023 - March 6, 2023</strong>.
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button variant="contained" color="primary" component={Link} to="/apply">
                  APPLY NOW
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <Divider />
      <div className={classes.infoContent}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6" >Main Campus</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
              <strong>College of Engineering and Architecture</strong>
              <ul>
                <li>Bachelor of Science in Civil Engineering</li>
                <li>Bachelor of Science in Computer Engineering</li>
                <li>Bachelor of Science in Electrical Engineering</li>
                <li>Bachelor of Science in Mechanical Engineering</li>
                <li>Bachelor of Science in Architecture</li>
                <li>Bachelor of Science in Fine Arts major in Industrial Design</li>
              </ul>
              <br />
              <strong>College of Teacher Education</strong>
              <ul>
                <li>Bachelor in Elementary Education</li>
                <li>Bachelor in Secondary Education major in English</li>
                <li>Bachelor in Secondary Education major in Filipino</li>
                <li>Bachelor in Secondary Education major in Mathematics</li>
                <li>Bachelor in Secondary Education major in Social Studies</li>
                <li>
                  Bachelor in Technology and Livelihood Education
                  <ul>
                    <li><em>major in Industrial Technology</em></li>
                    <li><em>major in Home Economics</em></li>
                  </ul>
                </li>
                <li>
                  Bachelor in Technical Vocational Teacher Education
                  <ul>
                    <li><em>major in Beauty Care and Wellness</em></li>
                    <li><em>major in Automotive Technology</em></li>
                    <li><em>major in Civil and Construction Technology</em></li>
                    <li><em>major in Electrical Technology</em></li>
                    <li><em>major in Food and Services Management</em></li>
                    <li><em>major in Garments and Fashion Design</em></li>
                    <li><em>major in Hotel and Restaurant Services Technology</em></li>
                  </ul>
                </li>
                <li>Bridging Course</li>
              </ul>
              <br />
              <strong>College of Business Arts and Sciences</strong>
              <ul>
                <li>Bachelor of Science in Psychology</li>
                <li>Bachelor of Science in Entrepreneurship</li>
                <li>Bachelor of Science in Hospitality Management</li>
                <li>Bachelor of Science in Tourism Management</li>
                <li>Bachelor of Science in Office Administration</li>
              </ul>
              <br />
              <strong>College of Technology and Allied Sciences</strong>
              <ul>
                <li>Bachelor of Science in Electrical Technology</li>
                <li>Bachelor of Science in Electronics Technology</li>
                <li>
                  Bachelor of Science in Industrial Technology
                  <ul>
                    <li><em>major in Automotive Technology</em></li>
                    <li><em>major in Civil Technology</em></li>
                    <li><em>major in Cosmetology Technology</em></li>
                    <li><em>major in Drafting Technology</em></li>
                    <li><em>major in Food Preparation and Service Mangement</em></li>
                    <li><em>major in Garments Technology</em></li>
                    <li><em>major in Heating, Ventilating, Air-Conditioning, and Refrigeration Technology</em></li>
                    <li><em>major in Mechanical Technology</em></li>
                    <li><em>major in Wood Technology</em></li>
                  </ul>
                </li>
                <li>Diploma in Electrical Technology</li>
                <li>Diploma in Electronics Technology</li>
                <li>
                  Diploma in Industrial Technology
                  <ul>
                    <li><em>major in Automotive Technology</em></li>
                    <li><em>major in Civil Technology</em></li>
                    <li><em>major in Cosmetology Technology</em></li>
                    <li><em>major in Drafting Technology</em></li>
                    <li><em>major in Food Preparation and Service Mangement</em></li>
                    <li><em>major in Garments Technology</em></li>
                    <li><em>major in Heating, Ventilating, Air-Conditioning, and Refrigeration Technology</em></li>
                    <li><em>major in Mechanical Technology</em></li>
                    <li><em>major in Wood Technology</em></li>
                  </ul>
                </li>
              </ul>
              <br />
              <strong>College of Advanced Studies</strong>
              <ul>
                <li>Doctor of Education major in Educational Management</li>
                <li>Doctor of Philosophy major in Technology Management</li>
                <li>Master in Public Administration</li>
                <li>Master of Science in Mathematics</li>
                <li>Master of Education major in Educational Management</li>
                <li>
                  Master of Arts in Teaching Vocational Education
                  <ul>
                    <li><em>major in Automotive Technology</em></li>
                    <li><em>major in Civil Technology</em></li>
                    <li><em>major in Drafting Technology</em></li>
                    <li><em>major in Electrical Technology</em></li>
                    <li><em>major in Electronics Technology</em></li>
                    <li><em>major in Food Technology</em></li>
                    <li><em>major in Garments Technology</em></li>
                    <li><em>major in Mechanical Technology</em></li>
                  </ul>
                </li>
                <li>
                  Master of Science in Industrial Technology
                  <ul>
                    <li><em>major in Automotive Technology</em></li>
                    <li><em>major in Civil Technology</em></li>
                    <li><em>major in Drafting Technology</em></li>
                    <li><em>major in Electrical Technology</em></li>
                    <li><em>major in Electronics Technology</em></li>
                    <li><em>major in Food Technology</em></li>
                    <li><em>major in Garments Technology</em></li>
                    <li><em>major in Mechanical Technology</em></li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6" >Balilihan Campus</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
                <strong>College of Technology and Allied Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Information Technology</li>
                  <li>Bachelor of Science in Electrical Technology</li>
                  <li>Bachelor of Science in Electronics Technology</li>
                  <li>
                    Bachelor of Science in Industrial Technology
                    <ul>
                      <li><em>major in Food Preparation and Service Management</em></li>
                      <li><em>major in Garments Technology</em></li>
                    </ul>
                  </li>
                </ul>
                <br />
                <strong>College of Criminal Justice</strong>
                <ul>
                  <li>Bachelor of Science in Criminology</li>
                </ul>
                <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6" >Bilar Campus</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
               <strong>College of Agriculture and Natural Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Agriculture</li>
                  <li>Bachelor of Science in Agricultural and Biosystems Engineering</li>
                  <li>Bachelor of Science in Environmental Science</li>
                  <li>Bachelor of Science in Forestry</li>
                </ul>
                <br />
                <strong>College of Teacher Education</strong>
                <ul>
                  <li>Bachelor in Elementary Education major in General Education</li>
                  <li>
                    Bachelor in Secondary Education
                    <ul>
                      <li><em>Egnlish</em></li>
                      <li><em>Filipino</em></li>
                      <li><em>Mathematics</em></li>
                    </ul>
                  </li>
                  <li>
                    Bachelor in Technology and Livelihood Education
                    <ul>
                      <li><em>major in Home Economics</em></li>
                      <li><em>major in Agri-Fishery Arts</em></li>
                    </ul>
                  </li>
                </ul>
                <br />
                <strong>College of Technology and Allied Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Entrepreneurship</li>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Hospitality Management</li>
                  <li>Bachelor of Science in Office Administration</li>
                  <li>
                    Bachelor of Science in Industrial Technology
                    <ul>
                      <li><em>major in Automotive Technology</em></li>
                      <li><em>major in Food Preparationa and Service Management</em></li>
                    </ul>
                  </li>
                </ul>
                <br />
                <strong>College of Advanced Studies</strong>
                <ul>
                  <li>Doctor of Philosophy major in Community Development</li>
                  <li>
                    Master in Agriculture and Development
                    <ul>
                      <li><em>major in Community Development</em></li>
                      <li><em>major in Agricultural Development</em></li>
                      <li><em>major in Agricultural Education</em></li>
                      <li><em>major in Rural Systems Management</em></li>
                    </ul>
                  </li>
                  <li>Master in Public Administration major Personnel Development</li>
                  <li>Master in Education major in Educational Management</li>
                </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6" >Calape Campus</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
                <strong>College of Teacher Education</strong>
                <ul>
                  <li>Bachelor in Elementary Education</li>
                  <li>
                    Bachelor in Secondary Education
                    <ul>
                      <li><em>major in English</em></li>
                      <li><em>major in Mathematics</em></li>
                    </ul>
                  </li>
                </ul>
                <br />
                <strong>College of Technology and Allied Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Fisheries</li>
                  <li>
                    Bachelor of Science in Industrial Technology
                    <ul>
                      <li><em>major in Food Preparation and Service Management</em></li>
                      <li><em>major in Eletrical Technology</em></li>
                    </ul>
                  </li>
                  <li>Midwifery</li>
                </ul>
                <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6" >Candijay Campus</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
               <strong>College of Fisheries and Marine Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Fisheries</li>
                  <li>Bachelor of Science in Marine Biology</li>
                  <li>Bachelor of Science in Environmental Science major in Coastal Resource Management</li>
                </ul>
                <br />
                <strong>College of Teacher Education</strong>
                <ul>
                  <li>Bachelor in Elementary Education</li>
                  <li>
                    Bachelor in Secondary Education
                    <ul>
                      <li><em>major in English</em></li>
                      <li><em>major in Filipino</em></li>
                      <li><em>major in Science</em></li>
                      <li><em>major in Mathematics</em></li>
                    </ul>
                  </li>
                </ul>
                <br />
                <strong>College of Technology and Allied Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Office Administration</li>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Hospitality Management</li>
                </ul>
                <br />
                <strong>College of Advanced Studies</strong>
                <ul>
                  <li>Master in Education major in Educational Management</li>
                </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6" >Clarin Campus</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">
                <strong>College of Teacher Education</strong>
                <ul>
                  <li>Bachelor in Elementary Education</li>
                  <li>Bachelor in Secondary Education major in Mathematics</li>
                  <li>Bachelor of Technology and Livelihood Education major in Home Economics</li>
                  <li>Bridging Course</li>
                </ul>
                <br />
                <strong>College of Technology and Allied Sciences</strong>
                <ul>
                  <li>Bachelor of Science in Computer Science</li>
                  <li>Bachelor of Science in Hospitality Management</li>
                  <li>Bachelor of Science in Environmental Science major in Coastal Resource Management</li>
                </ul>
                <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </main>
  );
}