import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(6, 0),
    color: theme.palette.common.white,
  },

  divider: {
    backgroundColor: theme.palette.common.white,
    marginTop: 20,
  },

  white: {
    color: theme.palette.common.white,
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <div>
          <Typography variant="h5" align="left" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            For queries, questions, and concerns contact us at:
          </Typography>
        </div>
        <Divider className={classes.divider}/>
        <div style={{padding: 20}}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" align="left" gutterBottom>
                Email
              </Typography>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="admission@bisu.edu.ph"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Main Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="guidance.balilihan@bisu.edu.ph"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Balilihan Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="guidance.bilar@bisu.edu.ph"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Bilar Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="guidance.calape@bisu.edu.ph"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Calape Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="guidance.candijay@bisu.edu.ph"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Candijay Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="mariajennifer.cubillo@bisu.edu.ph"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Clarin Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" align="left" gutterBottom>
                Facebook
              </Typography>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText>
                    <a className={classes.white} rel="noopener noreferrer" style={{textDecoration: "none"}} href="https://fb.com/bisuofficial" target="_blank">Main Campus</a>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText>
                    <a className={classes.white} rel="noopener noreferrer" style={{textDecoration: "none"}} href="https://fb.com/bisubalilihanguidancecenter" target="_blank">Balilihan Campus</a>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText>
                    <a className={classes.white} rel="noopener noreferrer" style={{textDecoration: "none"}} href="https://fb.com/bisu.bilar.2009" target="_blank">Bilar Campus</a>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText>
                    <a className={classes.white} rel="noopener noreferrer" style={{textDecoration: "none"}} href="https://fb.com/bisucalapeofficial" target="_blank">Calape Campus</a>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText>
                    <a className={classes.white} rel="noopener noreferrer" style={{textDecoration: "none"}} href="https://fb.com/bisucandijaypim" target="_blank">Candijay Campus</a>
                  </ListItemText>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText>
                    <a className={classes.white} rel="noopener noreferrer" style={{textDecoration: "none"}} href="https://fb.com/Bisu-Clarin-Guidance-Center-301126797051300" target="_blank">Clarin Campus</a>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" align="left" gutterBottom>
                Mobile
              </Typography>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="09106986797 / 09950691927"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Main Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="09102643256"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Balilihan Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="09490496058 / 09461806441"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Bilar Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="09358609124"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Calape Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="09557664683 / 09487491401"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Candijay Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="09511318783"
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.white}
                        >
                          Clarin Campus
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Container>
    </footer>
  );
}
