import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const Response = (props) => {
  const { open, setIsResponseOpen, data, setResponse } = props;
  const handleClose = () => {
    setResponse({});
    setIsResponseOpen(false);
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-title">{data.id ? "Application Submitted" : data.error ? "Error" : ""}</DialogTitle>
        <DialogContent>
          {
            data.id ? (
              <>
                <DialogContentText id="alert-dialog-description">
                  Check the email you provided for the confirmation message.
                  You can track the status of your application using your Application ID below.
                </DialogContentText>
                <DialogContentText style={{ fontSize: 26, fontWeight: 'bold', textAlign: 'center' }}>
                  {data.id}
                </DialogContentText>
              </>
            ) : data.error ? (
              <ErrorResponse error={data.error} />
            ) : (
              <CircularProgress />
            )
          }
        </DialogContent>
        {
          (data.id || data.error) &&
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>Close</Button>
          </DialogActions>
        }
      </Dialog>
    </div>
  )
};

const ErrorResponse = (props) => {
  return (
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {props.error}
      </DialogContentText>
    </DialogContent>
  );
}

export default Response;
