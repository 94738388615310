import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
// import CreateAppForm from './CreateAppForm';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'
import { getSearchExamLink } from '../utils/getLink';
// const { writeFileSync, readFileSync } = require("fs");

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  modal: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
    position:'absolute',
    top:'10%',
    left:'10%',
    height:'100%',
  },
  closebutton:{
    float: 'right',
    color: 'black',
    fontSize: '13px',
  },
  title: {
    flexGrow: 1,
    display: 'none',
    color: theme.palette.common.white,
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '22ch',
      '&:focus': {
        width: '26ch',
      },
    },
  },
  list: {
    width: 250,
  },
  normal_text: {
    fontWeight: 'normal',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Appbar() {
  const classes = useStyles()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [openMsg, setOpenMsg] = React.useState(false);
  const [openMsg2, setOpenMsg2] = React.useState(false);
  const [appId, setAppId] = useState('')
  const [response, setResponse] = useState({});
  const [dialogTrack, setDialogTrack] = React.useState(false);

  const handleOpenMsgFound = () => {  setOpenMsg(true);  };
  const handleCloseMsg = () => { setOpenMsg(false);  };

  const handleOpenDialogTrack = () => { setAppId(''); setDialogTrack(true); }
  const handleCloseDialogTrack = () => { setDialogTrack(false) }

  const handleOpenMsgNotFound = () => {  setOpenMsg2(true);  };
  const handleCloseMsg2 = () => { setOpenMsg2(false);  };

  const handleChange = (e) =>{
    setAppId(e.target.value)
  }

  const trackApplication = () => {
    var formData = new FormData();
    const data = {
       application:{
           id: parseInt(appId,10),
       }
    }
    formData.append('data', JSON.stringify(data));
    const requestOptions = {
      method: 'POST',
      body: formData
    }
    fetch(process.env.REACT_APP_API + '/trackapplication', requestOptions)
     .then(response => response.json())
     .then(data => {
       if (data.id==="0" || data.id===0){
         fetch(process.env.REACT_APP_API + '/trackrejectedapplication', requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.id==="0" || data.id===0){
              handleOpenMsgNotFound()
            }else{
              handleOpenMsgFound()
              // createAppForm()
              setResponse({
                ...data,
                status: "REJECTED",
                id: data.application_id
              })
            }
          });
       }else{
         handleOpenMsgFound()
         // createAppForm()
         setResponse(data)
         
       }
     });

  }

  const keyPress = (e) =>{
    if(e.keyCode === 13){
      trackApplication()
    }
  }


  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <img
            src={'/assets/images/bisu_logo.png'}
            alt={'BISU Logo'}
            loading="lazy"
            width="50"
            height="50"
            style={{paddingRight: '10px'}}
          />
          <a className={classes.title} href="/" style={{ textDecoration: 'none' }}>
            <Typography  variant="h6" noWrap style={{ color: 'white' }}>
            Online Application for Admission
            </Typography>
          </a>
          <Typography variant="h6" noWrap>
            Track Application
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Enter Application ID"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={appId}
              onChange={handleChange}
              onKeyDown={keyPress}
            />
          </div>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => setMenuIsOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right"
            open={menuIsOpen}
            onClose={() => setMenuIsOpen(!menuIsOpen)}
          >
            <div className={classes.list}>
              <List>
                <ListItem button component={Link} to="/">
                  <ListItemText primary={"Home"} />
                </ListItem>
                <ListItem button component={Link} to="/apply">
                  <ListItemText primary={"Apply Now"} />
                </ListItem>
                <ListItem button onClick={(e) => handleOpenDialogTrack() }>
                  <ListItemText primary={"Track Application"} />
                </ListItem>
                <ListItem button component={Link} to="/programs">
                  <ListItemText primary={"Programs Offered"} />
                </ListItem>
                <ListItem button component="a" 
                    href="https://docs.google.com/document/d/e/2PACX-1vTb-hVxbI8WF4YSTkBOZpR_AqA0-DuUNAtXHqeCNrekXo45e6u7eVawsfXWasDrAgrAYwYA7z3VAHNk/pub"
                    target="_blank"
                    rel="noopener noreferrer">
                  <ListItemText primary={"Announcements"} />
                </ListItem>
                <ListItem button component="a" 
                    href={getSearchExamLink()}
                    target="_blank"
                    rel="noopener noreferrer">
                  <ListItemText primary={"Track Examination Schedule"} />
                </ListItem>
              </List>
            </div>
          </Drawer>
        </Toolbar>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openMsg}
          onClose={handleCloseMsg}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openMsg}>
            <Box sx={style}>
              <div className={classes.paper}>
                <IconButton className={classes.closebutton} onClick={handleCloseMsg} aria-label="close" color="primary">
                  <CloseIcon />
                </IconButton>

                <Typography variant="h4">Application Status: <Box component="span" fontWeight="bold">{response.status}</Box></Typography>
                <Divider />
                <br />
                <Typography variant="subtitle1">Application ID: <Box component="span" fontWeight="bold">{response.id}</Box></Typography>
                <Typography variant="subtitle1">Name: <Box component="span" fontWeight="bold">{response.last_name+", "+response.first_name}</Box></Typography>
                <Typography variant="subtitle1">Campus Applied: <Box component="span" fontWeight="bold">{response.campus}</Box></Typography>
                <Typography variant="subtitle1">First Priority Course: <Box component="span" fontWeight="bold">{response.coursecode}</Box></Typography>
                {(response.coursecode2!=="")?
                  <div>
                    <Typography variant="subtitle1">Second Priority Course: <Box component="span" fontWeight="bold">{response.coursecode2}</Box></Typography>
                    <Typography variant="subtitle1">Third Priority Course: <Box component="span" fontWeight="bold">{response.coursecode3}</Box></Typography>
                  </div>
                  :<div />
                }
                { (response.remarks!=="")?
                  <div>
                    <Divider />
                    <br />
                    <Typography
                      style={{whiteSpace: 'pre-line'}}
                      variant="subtitle1">
                      Reason: <Box component="span" fontWeight="bold">{response.remarks}</Box>
                    </Typography>
                  </div>
                  :<div>
                  </div>
                }
                {
                  (response.status==="APPROVED")?
                  <div>
                    <Divider />
                    <br />
                    <Typography variant="subtitle1">Examination Venue: <Box component="span" fontWeight="bold">{response.exam_venue}</Box></Typography>                
                    <Divider />
                    <br />
                    <Button variant="outlined" color="primary" href={getSearchExamLink()}>
                        Track Examination Schedule
                    </Button>
                  </div>
                  :<div />
                }
              </div>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openMsg2}
          onClose={handleCloseMsg2}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openMsg2}>
            <Box sx={style}>
              <div className={classes.paper}>
                <IconButton className={classes.closebutton} onClick={handleCloseMsg2} aria-label="close" color="primary">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h4">Application NOT Found</Typography>
                <Divider />
                <br />
                <Typography variant="h6">Please enter the application ID that was sent to you during the application process.</Typography>
              </div>
            </Box>
          </Fade>
        </Modal>

        <Dialog
              open={dialogTrack}
              onClose={handleCloseDialogTrack}
              maxWidth="lg"
              aria-labelledby="max-width-dialog-title">
          <DialogTitle id="form-dialog-title">Track Application</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your Application ID.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Application ID"
              type="text"
              value={appId}
              onChange={handleChange}
              fullWidth
              onKeyDown={keyPress}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogTrack} variant="contained" color="primary">
              Cancel
            </Button>
            <Button onClick={trackApplication} variant="contained" color="secondary">
              Track
            </Button>
          </DialogActions>
        </Dialog>

      </AppBar>
    </div>
  )
}
