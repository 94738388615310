import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { getSearchExamLink } from '../utils/getLink';


const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },

    infoContent: {
        padding: theme.spacing(8, 0, 7),
    },

    requirementsIcon: {
        color: theme.palette.success.main,
    }
}));

export default function Landing() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return(
        <main>
            <div className={classes.heroContent}>
                <Container maxWidth="lg">
                    <Typography component="h1" variant="h2" align="center" color="primary" gutterBottom>
                        Online Application for Admission
                    </Typography>
                    <div className={classes.heroButtons}>
                    <Grid container spacing={2} justify="center">
                        <Grid item>
                          <Button variant="contained" color="primary" href="/apply">
                              APPLY NOW
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" color="primary" href="/programs">
                              Programs Offered
                          </Button>
                        </Grid>
                    </Grid>
                    {/* <Typography variant="body2" align="center" color="textSecondary">
                        <em><small>Application period is currently close.</small></em>
                    </Typography> */}
                    </div>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <iframe
                            src="https://docs.google.com/document/d/1YHleVVIIG1Wq59XTR3xWWNdOB0caCxaWOIrqmn2dreM/pub?embedded=true"
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Link
                            href="https://docs.google.com/document/d/1YHleVVIIG1Wq59XTR3xWWNdOB0caCxaWOIrqmn2dreM/pub"
                            target="_blank"
                            rel="noopener noreferrer"
                        >Click here for more info.
                        </Link>
                    </Box>         
                </Container>
            </div>
            <Divider />
            <div className={classes.infoContent}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Requirements</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container maxWidth="lg" >
                            <Box display="flex" justifyContent="center">
                                <iframe 
                                    src="https://docs.google.com/document/d/e/2PACX-1vT70v5bPNP_MG-mWt0P27XbcUzs5qDkyEpVFpNsADKDRlthAN1KrjRwjZ7v9rfMoQeeppQZfrVS-LoC/pub?embedded=true"
                                    width="793.7px"
                                    height="650.52px"
                                    style={{ border: 'none' }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Link
                                    href="https://docs.google.com/document/d/e/2PACX-1vT70v5bPNP_MG-mWt0P27XbcUzs5qDkyEpVFpNsADKDRlthAN1KrjRwjZ7v9rfMoQeeppQZfrVS-LoC/pub"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Click here for more info.
                                </Link>
                            </Box>         
                        </Container>
                        {/* <List>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Certified true copy of the 1st Semester grade for school term 2020-2021.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Ceritification of Good Moral Character.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        1 pc. 2x2, recent, colored, formal picture with name tag.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Certificate of Transfer of Credentials <em><small>(For transferees only.)</small></em>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Informative Copy of TOR <em><small>(For transferees only.)</small></em>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Ishihara Test Result <em><small>(For Electrical and Computer Engineering,
                                        Electrical, Electronics, and Automotive Technology applicants only.)</small></em>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For Teacher Education programs, the average grade is at least 85 (no rounding off).
                                        There should be no grade below 80 in all subjects (in all terms) of the first
                                        semester grade.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For engineering programs, the average grade is at least 85 for Non-STEM
                                        with no grade below 80 in the final rating, and an average of 80 for STEM,
                                        with no grade below 80 for Mathematics and Science in the final rating
                                        of the first semester grade.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For board programs, average is at least 80 in the final rating of the first
                                        semester grade.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For non-degree programs, average is at least 75 in the final rating
                                        of the first semester grade.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For proceeders, at least 2.5 average of the last program attended.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For Teacher Education Transferees, the average is at least 2.0 (no rounding off)
                                        for the last semestr attended. There should be no grade below 80 in all subjects
                                        (in all terms) of the last semester atttended.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For Transferees of engineering degree programs average is at least 2.5 of the last
                                        semester attended.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Transferees fo other board degree programs, average is at least 2.5 of the last
                                        semester attended, with no grade below 2.5 in all subjects in the final rating.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For transferees of non-board programs, applicants must have an average of at least
                                        2.5 in the last semester attended, with no grade below 3.0 in all subjects in
                                        the final rating.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For bridging, general average of at least 2.0 of the program last attended with no grade
                                        below 2.5 in all subjects in all terms.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        For Masteral and Doctoral Degree Programs, at least an average rating of 2.5 of the
                                        last program graduated.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Qualifications</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Container maxWidth="lg" >
                            <Box display="flex" justifyContent="center">
                                <iframe 
                                    src="https://docs.google.com/document/d/e/2PACX-1vSxz8E1yeJOyeeGN2GYzxT6qJSBHG9rSCjKN2_B6147_y7qG4xNGve2B2Hj41wgQtlZRqRPQqsEWvJV/pub?embedded=true"
                                    width="793.7px"
                                    height="650.52px"
                                    style={{ border: 'none' }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="flex-end">
                                <Link
                                    href="https://docs.google.com/document/d/e/2PACX-1vSxz8E1yeJOyeeGN2GYzxT6qJSBHG9rSCjKN2_B6147_y7qG4xNGve2B2Hj41wgQtlZRqRPQqsEWvJV/pub"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Click here for more info.
                                </Link>
                            </Box>         
                        </Container>
                        {/* <List>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Click on the <b>Apply Now</b> button.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Fill in the application form completely.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Upload clear scanned copies of all the required documents.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Review that all the information you provided are correct,
                                        and all required documents uploaded are complete.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Click on the <b>Submit</b> button to complete your application.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List> */}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Application Procedure</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Click on the <b>Apply Now</b> button.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Fill in the application form completely.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Upload clear scanned copies of all the required documents.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Review that all the information you provided are correct,
                                        and all required documents uploaded are complete.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon className={classes.requirementsIcon}>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body2">
                                        Click on the <b>Submit</b> button to complete your application.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Application Verification</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemText>
                                    <Typography>
                                        Verificiation of applications will be conducted by our assigned personnel as to the completeness of 
                                        the requirements and correctness of data provided.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography>
                                        Applications with incomplete requirements will be rejected upon evaluation, applicants however may 
                                        re-apply if his/her application is rejected.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Typography>
                                        Any false or tampered information of the required documents uploaded in the online application will
                                        automatically invalidate the application of the student applicant and permanently prohibit him/her
                                        in pursuing to study at Bohol Island State University.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6" >Privacy Statement</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <Typography variant="body1">
                                Bohol Island State University (BISU) recognizes its responsibilities under the Republic Act No. 10173 (Act), also known as the Data Privacy Act of 2012, with respect to the data it collect, record, organize, update, use, consolidate or destruct from applicants who will provide their personal information for the Online Admission Application. The personal data obtained from this portal is entered and stored within the authorized information and communications system and will only be accessed by authorized personnel.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Furthermore, the information collected and stored in the portal shall only be used to determine the qualification of the applicants to the university's curriculum offerings, and serve as basis for ranking. BISU shall not disclose the participant's personal information without their consent.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Proceeding to the application indicates that the participant has read the Data Privacy Statement and expresses consent to collect, record, organize, update or modify, retrieve, consult, use, consolidate, block, erase or destruct my personal data as part of my information.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Proceeding hereby also affirms the participant's right to be informed, object to processing, access and rectify, suspend or withdraw his/her personal data, and be indemnified in case of damages pursuant to the provisions of the Republic Act No. 10173 of the Philippines, Data Privacy Act of 2012 and its corresponding Implementing Rules and Regulations.
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        </main>
    );
}
