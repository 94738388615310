import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';

ReactDOM.render(
  <React.Fragment>
    {/* http://localhost:7654/api */}
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
