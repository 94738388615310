import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  }
}));

const AcademicDetails = (props) => {
  const classes = useStyles();
  const { academicDetails, setAcademicDetails } = props;
  const handleChange = (event) => {
    let value = null;
    if (event.target.type === 'number' && event.target.value.length !== 0) {
      value = parseFloat(event.target.value);
    } else {
      value = event.target.value;
    }
    setAcademicDetails({
      ...academicDetails,
      [event.target.name]: value
    });
  }
  return (
    <div className={classes.root}>
      <Typography variant="body2" color="textSecondary" paragraph>
        SHS 1st Semester Grade 12 General Average <em><small>(for SHS applicants)</small></em>
        <br />
        4th Year HS General Average <em><small>(for HS graduate applicants)</small></em>
        <br />
        GWA or General Average of last semester attended <em><small>(for transferees)</small></em>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField name="track_strand" label="Track Strand" value={academicDetails.track_strand} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name="lrn" label="LRN" value={academicDetails.lrn} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name="general_average" label="General Average" value={academicDetails.general_average} onChange={handleChange} inputProps={{ type: "number", min: 0, step: 0.1 }} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="last_school_attended" label="Last School Attended" value={academicDetails.last_school_attended} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="last_school_attended_address" label="School Address" value={academicDetails.last_school_attended_address} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField name="date_graduated" label="Date Graduated" value={academicDetails.date_graduated} placeholder="Jan 2, 2006" onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField name="honors_received" label="Honor's Received" value={academicDetails.honors_received} onChange={handleChange} fullWidth />
        </Grid>
      </Grid>
    </div>
  );
}

export default AcademicDetails;
